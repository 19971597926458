@import "bootstrap/scss/functions";

/* ========== BOOTSTRAP COLORS ========== */

/* Contrast ratio */
$min-contrast-ratio: 3;

/* Base colors */
$white: #ffffff;
$black: #000000;
$gray: #6c757d;
$blue: #0030b9;
$green: #198754;
$red: #dc3545;
$orange: #ec6b00;
$cyan: #17a2b8;
$pink: #d63384;
$light-pink: #ff96b2;

/* Gray variations */
$gray-100: #e9ecef;
$gray-200: #dee2e6;
$gray-300: #ced4da;
$gray-400: #adb5bd;
$gray-500: $gray;
$gray-600: #495057;
$gray-700: #343a40;
$gray-800: #212529;
$gray-900: #1a1e21;

$gray-100-rgb: to-rgb($gray-100);
$gray-200-rgb: to-rgb($gray-200);
$gray-300-rgb: to-rgb($gray-300);
$gray-400-rgb: to-rgb($gray-400);
$gray-500-rgb: to-rgb($gray-500);
$gray-600-rgb: to-rgb($gray-600);
$gray-700-rgb: to-rgb($gray-700);
$gray-800-rgb: to-rgb($gray-800);
$gray-900-rgb: to-rgb($gray-900);

/* Blues variations */
$blue-100: tint-color($blue, 80%);
$blue-200: tint-color($blue, 60%);
$blue-300: tint-color($blue, 40%);
$blue-400: tint-color($blue, 20%);
$blue-500: $blue;
$blue-600: shade-color($blue, 20%);
$blue-700: shade-color($blue, 40%);
$blue-800: shade-color($blue, 60%);
$blue-900: shade-color($blue, 80%);

$blue-100-rgb: to-rgb($blue-100);
$blue-200-rgb: to-rgb($blue-200);
$blue-300-rgb: to-rgb($blue-300);
$blue-400-rgb: to-rgb($blue-400);
$blue-500-rgb: to-rgb($blue-500);
$blue-600-rgb: to-rgb($blue-600);
$blue-700-rgb: to-rgb($blue-700);
$blue-800-rgb: to-rgb($blue-800);
$blue-900-rgb: to-rgb($blue-900);

/* Greens variations */
$green-100: tint-color($green, 80%);
$green-200: tint-color($green, 60%);
$green-300: tint-color($green, 40%);
$green-400: tint-color($green, 20%);
$green-500: $green;
$green-600: shade-color($green, 20%);
$green-700: shade-color($green, 40%);
$green-800: shade-color($green, 60%);
$green-900: shade-color($green, 80%);

$green-100-rgb: to-rgb($green-100);
$green-200-rgb: to-rgb($green-200);
$green-300-rgb: to-rgb($green-300);
$green-400-rgb: to-rgb($green-400);
$green-500-rgb: to-rgb($green-500);
$green-600-rgb: to-rgb($green-600);
$green-700-rgb: to-rgb($green-700);
$green-800-rgb: to-rgb($green-800);
$green-900-rgb: to-rgb($green-900);

/* Reds variations */
$red-100: tint-color($red, 80%);
$red-200: tint-color($red, 60%);
$red-300: tint-color($red, 40%);
$red-400: tint-color($red, 20%);
$red-500: $red;
$red-600: shade-color($red, 20%);
$red-700: shade-color($red, 40%);
$red-800: shade-color($red, 60%);
$red-900: shade-color($red, 80%);

$red-100-rgb: to-rgb($red-100);
$red-200-rgb: to-rgb($red-200);
$red-300-rgb: to-rgb($red-300);
$red-400-rgb: to-rgb($red-400);
$red-500-rgb: to-rgb($red-500);
$red-600-rgb: to-rgb($red-600);
$red-700-rgb: to-rgb($red-700);
$red-800-rgb: to-rgb($red-800);
$red-900-rgb: to-rgb($red-900);

/* Orange variations */
$orange-100: tint-color($orange, 80%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 80%);

$orange-100-rgb: to-rgb($orange-100);
$orange-200-rgb: to-rgb($orange-200);
$orange-300-rgb: to-rgb($orange-300);
$orange-400-rgb: to-rgb($orange-400);
$orange-500-rgb: to-rgb($orange-500);
$orange-600-rgb: to-rgb($orange-600);
$orange-700-rgb: to-rgb($orange-700);
$orange-800-rgb: to-rgb($orange-800);
$orange-900-rgb: to-rgb($orange-900);

/* Cyans variations */
$cyan-100: tint-color($cyan, 80%);
$cyan-200: tint-color($cyan, 60%);
$cyan-300: tint-color($cyan, 40%);
$cyan-400: tint-color($cyan, 20%);
$cyan-500: $cyan;
$cyan-600: shade-color($cyan, 20%);
$cyan-700: shade-color($cyan, 40%);
$cyan-800: shade-color($cyan, 60%);
$cyan-900: shade-color($cyan, 80%);

$cyan-100-rgb: to-rgb($cyan-100);
$cyan-200-rgb: to-rgb($cyan-200);
$cyan-300-rgb: to-rgb($cyan-300);
$cyan-400-rgb: to-rgb($cyan-400);
$cyan-500-rgb: to-rgb($cyan-500);
$cyan-600-rgb: to-rgb($cyan-600);
$cyan-700-rgb: to-rgb($cyan-700);
$cyan-800-rgb: to-rgb($cyan-800);
$cyan-900-rgb: to-rgb($cyan-900);

/* Theme colors */
$primary: $blue;
$secondary: $gray;
$success: $green;
$info: $blue;
$warning: $orange;
$danger: $red;
$highlight: $blue;

$light: #f8f9fa;
$light-rgb: to-rgb($light);
$dark: $gray-700;
$dark-rgb: $gray-700-rgb;

$disabled-mask: linear-gradient(0deg, rgba($white, 0.65), rgba($white, 0.65));
$btn-disabled-opacity: 0.35;

/* Text colors */
$text-body: $gray-800;
$text-muted: $gray-500;
$text-white: $white;
$link-color: #0d6efd;
$link-color-hover: #0a58ca;

/* Border colors */
$gray-border: $gray-300;

/* ========== ATLAS COLORS ========== */

/* Atlas theme colors */
$atlas-primary: var(--atlas-primary);
$atlas-secondary: var(--atlas-secondary);
$atlas-success: var(--atlas-success);
$atlas-warning: var(--atlas-warning);
$atlas-danger: var(--atlas-danger);
$atlas-highlight: var(--atlas-highlight);
$atlas-light: var(--atlas-light);
$atlas-dark: var(--atlas-dark);

$atlas-theme-colors: (
    "primary": $atlas-primary,
    "secondary": $atlas-secondary,
    "success": $atlas-success,
    "warning": $atlas-warning,
    "danger": $atlas-danger,
    "highlight": $atlas-highlight
);

/* Atlas theme contrasts */
$atlas-contrast-primary: var(--atlas-contrast-primary);
$atlas-contrast-secondary: var(--atlas-contrast-secondary);
$atlas-contrast-success: var(--atlas-contrast-success);
$atlas-contrast-warning: var(--atlas-contrast-warning);
$atlas-contrast-danger: var(--atlas-contrast-danger);
$atlas-contrast-highlight: var(--atlas-contrast-highlight);

$atlas-contrast-theme-colors: (
    "primary": $atlas-contrast-primary,
    "secondary": $atlas-contrast-secondary,
    "success": $atlas-contrast-success,
    "warning": $atlas-contrast-warning,
    "danger": $atlas-contrast-danger,
    "highlight": $atlas-contrast-highlight
);

/* Atlas theme rgb */
$atlas-primary-rgb: var(--atlas-primary-rgb);
$atlas-secondary-rgb: var(--atlas-secondary-rgb);
$atlas-success-rgb: var(--atlas-success-rgb);
$atlas-warning-rgb: var(--atlas-warning-rgb);
$atlas-danger-rgb: var(--atlas-danger-rgb);
$atlas-light-rgb: var(--atlas-light-rgb);
$atlas-dark-rgb: var(--atlas-dark-rgb);
$atlas-highlight-rgb: var(--atlas-highlight-rgb);

$atlas-rgb-colors-map: (
    "primary": $atlas-primary-rgb,
    "secondary": $atlas-secondary-rgb,
    "success": $atlas-success-rgb,
    "warning": $atlas-warning-rgb,
    "danger": $atlas-danger-rgb,
    "highlight": $atlas-highlight-rgb
);

/* Atlas variant primary colors */
$atlas-primary-100: var(--atlas-primary-100);
$atlas-primary-200: var(--atlas-primary-200);
$atlas-primary-300: var(--atlas-primary-300);
$atlas-primary-400: var(--atlas-primary-400);
$atlas-primary-500: var(--atlas-primary-500);
$atlas-primary-600: var(--atlas-primary-600);
$atlas-primary-700: var(--atlas-primary-700);
$atlas-primary-800: var(--atlas-primary-800);
$atlas-primary-900: var(--atlas-primary-900);

$atlas-primary-100-rgb: var(--atlas-primary-100-rgb);
$atlas-primary-200-rgb: var(--atlas-primary-200-rgb);
$atlas-primary-300-rgb: var(--atlas-primary-300-rgb);
$atlas-primary-400-rgb: var(--atlas-primary-400-rgb);
$atlas-primary-500-rgb: var(--atlas-primary-500-rgb);
$atlas-primary-600-rgb: var(--atlas-primary-600-rgb);
$atlas-primary-700-rgb: var(--atlas-primary-700-rgb);
$atlas-primary-800-rgb: var(--atlas-primary-800-rgb);
$atlas-primary-900-rgb: var(--atlas-primary-900-rgb);

/* Atlas variant secondary colors */
$atlas-secondary-100: var(--atlas-secondary-100);
$atlas-secondary-200: var(--atlas-secondary-200);
$atlas-secondary-300: var(--atlas-secondary-300);
$atlas-secondary-400: var(--atlas-secondary-400);
$atlas-secondary-500: var(--atlas-secondary-500);
$atlas-secondary-600: var(--atlas-secondary-600);
$atlas-secondary-700: var(--atlas-secondary-700);
$atlas-secondary-800: var(--atlas-secondary-800);
$atlas-secondary-900: var(--atlas-secondary-900);

$atlas-secondary-100-rgb: var(--atlas-secondary-100-rgb);
$atlas-secondary-200-rgb: var(--atlas-secondary-200-rgb);
$atlas-secondary-300-rgb: var(--atlas-secondary-300-rgb);
$atlas-secondary-400-rgb: var(--atlas-secondary-400-rgb);
$atlas-secondary-500-rgb: var(--atlas-secondary-500-rgb);
$atlas-secondary-600-rgb: var(--atlas-secondary-600-rgb);
$atlas-secondary-700-rgb: var(--atlas-secondary-700-rgb);
$atlas-secondary-800-rgb: var(--atlas-secondary-800-rgb);
$atlas-secondary-900-rgb: var(--atlas-secondary-900-rgb);

/* Atlas variant success colors */
$atlas-success-100: var(--atlas-success-100);
$atlas-success-200: var(--atlas-success-200);
$atlas-success-300: var(--atlas-success-300);
$atlas-success-400: var(--atlas-success-400);
$atlas-success-500: var(--atlas-success-500);
$atlas-success-600: var(--atlas-success-600);
$atlas-success-700: var(--atlas-success-700);
$atlas-success-800: var(--atlas-success-800);
$atlas-success-900: var(--atlas-success-900);

$atlas-success-100-rgb: var(--atlas-success-100-rgb);
$atlas-success-200-rgb: var(--atlas-success-200-rgb);
$atlas-success-300-rgb: var(--atlas-success-300-rgb);
$atlas-success-400-rgb: var(--atlas-success-400-rgb);
$atlas-success-500-rgb: var(--atlas-success-500-rgb);
$atlas-success-600-rgb: var(--atlas-success-600-rgb);
$atlas-success-700-rgb: var(--atlas-success-700-rgb);
$atlas-success-800-rgb: var(--atlas-success-800-rgb);
$atlas-success-900-rgb: var(--atlas-success-900-rgb);

/* Atlas variant warning colors */
$atlas-warning-100: var(--atlas-warning-100);
$atlas-warning-200: var(--atlas-warning-200);
$atlas-warning-300: var(--atlas-warning-300);
$atlas-warning-400: var(--atlas-warning-400);
$atlas-warning-500: var(--atlas-warning-500);
$atlas-warning-600: var(--atlas-warning-600);
$atlas-warning-700: var(--atlas-warning-700);
$atlas-warning-800: var(--atlas-warning-800);
$atlas-warning-900: var(--atlas-warning-900);

$atlas-warning-100-rgb: var(--atlas-warning-100-rgb);
$atlas-warning-200-rgb: var(--atlas-warning-200-rgb);
$atlas-warning-300-rgb: var(--atlas-warning-300-rgb);
$atlas-warning-400-rgb: var(--atlas-warning-400-rgb);
$atlas-warning-500-rgb: var(--atlas-warning-500-rgb);
$atlas-warning-600-rgb: var(--atlas-warning-600-rgb);
$atlas-warning-700-rgb: var(--atlas-warning-700-rgb);
$atlas-warning-800-rgb: var(--atlas-warning-800-rgb);
$atlas-warning-900-rgb: var(--atlas-warning-900-rgb);

/* Atlas variant danger colors */
$atlas-danger-100: var(--atlas-danger-100);
$atlas-danger-200: var(--atlas-danger-200);
$atlas-danger-300: var(--atlas-danger-300);
$atlas-danger-400: var(--atlas-danger-400);
$atlas-danger-500: var(--atlas-danger-500);
$atlas-danger-600: var(--atlas-danger-600);
$atlas-danger-700: var(--atlas-danger-700);
$atlas-danger-800: var(--atlas-danger-800);
$atlas-danger-900: var(--atlas-danger-900);

$atlas-danger-100-rgb: var(--atlas-danger-100-rgb);
$atlas-danger-200-rgb: var(--atlas-danger-200-rgb);
$atlas-danger-300-rgb: var(--atlas-danger-300-rgb);
$atlas-danger-400-rgb: var(--atlas-danger-400-rgb);
$atlas-danger-500-rgb: var(--atlas-danger-500-rgb);
$atlas-danger-600-rgb: var(--atlas-danger-600-rgb);
$atlas-danger-700-rgb: var(--atlas-danger-700-rgb);
$atlas-danger-800-rgb: var(--atlas-danger-800-rgb);
$atlas-danger-900-rgb: var(--atlas-danger-900-rgb);

/* Atlas variant highlight colors */
$atlas-highlight-100: var(--atlas-highlight-100);
$atlas-highlight-200: var(--atlas-highlight-200);
$atlas-highlight-300: var(--atlas-highlight-300);
$atlas-highlight-400: var(--atlas-highlight-400);
$atlas-highlight-500: var(--atlas-highlight-500);
$atlas-highlight-600: var(--atlas-highlight-600);
$atlas-highlight-700: var(--atlas-highlight-700);
$atlas-highlight-800: var(--atlas-highlight-800);
$atlas-highlight-900: var(--atlas-highlight-900);

$atlas-highlight-100-rgb: var(--atlas-highlight-100-rgb);
$atlas-highlight-200-rgb: var(--atlas-highlight-200-rgb);
$atlas-highlight-300-rgb: var(--atlas-highlight-300-rgb);
$atlas-highlight-400-rgb: var(--atlas-highlight-400-rgb);
$atlas-highlight-500-rgb: var(--atlas-highlight-500-rgb);
$atlas-highlight-600-rgb: var(--atlas-highlight-600-rgb);
$atlas-highlight-700-rgb: var(--atlas-highlight-700-rgb);
$atlas-highlight-800-rgb: var(--atlas-highlight-800-rgb);
$atlas-highlight-900-rgb: var(--atlas-highlight-900-rgb);

$atlas-variant-colors-map: (
    "primary": (
        "100": $atlas-primary-100,
        "200": $atlas-primary-200,
        "300": $atlas-primary-300,
        "400": $atlas-primary-400,
        "500": $atlas-primary-500,
        "600": $atlas-primary-600,
        "700": $atlas-primary-700,
        "800": $atlas-primary-800,
        "900": $atlas-primary-900
    ),
    "secondary": (
        "100": $atlas-secondary-100,
        "200": $atlas-secondary-200,
        "300": $atlas-secondary-300,
        "400": $atlas-secondary-400,
        "500": $atlas-secondary-500,
        "600": $atlas-secondary-600,
        "700": $atlas-secondary-700,
        "800": $atlas-secondary-800,
        "900": $atlas-secondary-900
    ),
    "success": (
        "100": $atlas-success-100,
        "200": $atlas-success-200,
        "300": $atlas-success-300,
        "400": $atlas-success-400,
        "500": $atlas-success-500,
        "600": $atlas-success-600,
        "700": $atlas-success-700,
        "800": $atlas-success-800,
        "900": $atlas-success-900
    ),
    "warning": (
        "100": $atlas-warning-100,
        "200": $atlas-warning-200,
        "300": $atlas-warning-300,
        "400": $atlas-warning-400,
        "500": $atlas-warning-500,
        "600": $atlas-warning-600,
        "700": $atlas-warning-700,
        "800": $atlas-warning-800,
        "900": $atlas-warning-900
    ),
    "danger": (
        "100": $atlas-danger-100,
        "200": $atlas-danger-200,
        "300": $atlas-danger-300,
        "400": $atlas-danger-400,
        "500": $atlas-danger-500,
        "600": $atlas-danger-600,
        "700": $atlas-danger-700,
        "800": $atlas-danger-800,
        "900": $atlas-danger-900
    ),
    "highlight": (
        "100": $atlas-highlight-100,
        "200": $atlas-highlight-200,
        "300": $atlas-highlight-300,
        "400": $atlas-highlight-400,
        "500": $atlas-highlight-500,
        "600": $atlas-highlight-600,
        "700": $atlas-highlight-700,
        "800": $atlas-highlight-800,
        "900": $atlas-highlight-900
    )
);

$atlas-variant-colors-rgb-map: (
    "primary": (
        "100": $atlas-primary-100-rgb,
        "200": $atlas-primary-200-rgb,
        "300": $atlas-primary-300-rgb,
        "400": $atlas-primary-400-rgb,
        "500": $atlas-primary-500-rgb,
        "600": $atlas-primary-600-rgb,
        "700": $atlas-primary-700-rgb,
        "800": $atlas-primary-800-rgb,
        "900": $atlas-primary-900-rgb
    ),
    "secondary": (
        "100": $atlas-secondary-100-rgb,
        "200": $atlas-secondary-200-rgb,
        "300": $atlas-secondary-300-rgb,
        "400": $atlas-secondary-400-rgb,
        "500": $atlas-secondary-500-rgb,
        "600": $atlas-secondary-600-rgb,
        "700": $atlas-secondary-700-rgb,
        "800": $atlas-secondary-800-rgb,
        "900": $atlas-secondary-900-rgb
    ),
    "success": (
        "100": $atlas-success-100-rgb,
        "200": $atlas-success-200-rgb,
        "300": $atlas-success-300-rgb,
        "400": $atlas-success-400-rgb,
        "500": $atlas-success-500-rgb,
        "600": $atlas-success-600-rgb,
        "700": $atlas-success-700-rgb,
        "800": $atlas-success-800-rgb,
        "900": $atlas-success-900-rgb
    ),
    "warning": (
        "100": $atlas-warning-100-rgb,
        "200": $atlas-warning-200-rgb,
        "300": $atlas-warning-300-rgb,
        "400": $atlas-warning-400-rgb,
        "500": $atlas-warning-500-rgb,
        "600": $atlas-warning-600-rgb,
        "700": $atlas-warning-700-rgb,
        "800": $atlas-warning-800-rgb,
        "900": $atlas-warning-900-rgb
    ),
    "danger": (
        "100": $atlas-danger-100-rgb,
        "200": $atlas-danger-200-rgb,
        "300": $atlas-danger-300-rgb,
        "400": $atlas-danger-400-rgb,
        "500": $atlas-danger-500-rgb,
        "600": $atlas-danger-600-rgb,
        "700": $atlas-danger-700-rgb,
        "800": $atlas-danger-800-rgb,
        "900": $atlas-danger-900-rgb
    ),
    "highlight": (
        "100": $atlas-highlight-100-rgb,
        "200": $atlas-highlight-200-rgb,
        "300": $atlas-highlight-300-rgb,
        "400": $atlas-highlight-400-rgb,
        "500": $atlas-highlight-500-rgb,
        "600": $atlas-highlight-600-rgb,
        "700": $atlas-highlight-700-rgb,
        "800": $atlas-highlight-800-rgb,
        "900": $atlas-highlight-900-rgb
    )
);

/* ========== EXPORTED COLORS ========== */
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "danger": $danger,
    "info": $info,
    "highlight": $highlight
);

$contrast-theme-colors: (
    "primary": $text-white,
    "secondary": $text-white,
    "success": $text-white,
    "warning": $text-white,
    "danger": $text-white,
    "info": $text-white,
    "highlight": $text-white
);

$rgb-colors-map: (
    "primary": $blue-500-rgb,
    "secondary": $gray-500-rgb,
    "success": $green-500-rgb,
    "warning": $orange-500-rgb,
    "danger": $red-500-rgb,
    "highlight": $blue-500-rgb
);

$variant-colors-map: (
    "primary": (
        "100": $blue-100,
        "200": $blue-200,
        "300": $blue-300,
        "400": $blue-400,
        "500": $blue-500,
        "600": $blue-600,
        "700": $blue-700,
        "800": $blue-800,
        "900": $blue-900
    ),
    "secondary": (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
    ),
    "success": (
        "100": $green-100,
        "200": $green-200,
        "300": $green-300,
        "400": $green-400,
        "500": $green-500,
        "600": $green-600,
        "700": $green-700,
        "800": $green-800,
        "900": $green-900
    ),
    "warning": (
        "100": $orange-100,
        "200": $orange-200,
        "300": $orange-300,
        "400": $orange-400,
        "500": $orange-500,
        "600": $orange-600,
        "700": $orange-700,
        "800": $orange-800,
        "900": $orange-900
    ),
    "danger": (
        "100": $red-100,
        "200": $red-200,
        "300": $red-300,
        "400": $red-400,
        "500": $red-500,
        "600": $red-600,
        "700": $red-700,
        "800": $red-800,
        "900": $red-900
    ),
    "highlight": (
        "100": $blue-100,
        "200": $blue-200,
        "300": $blue-300,
        "400": $blue-400,
        "500": $blue-500,
        "600": $blue-600,
        "700": $blue-700,
        "800": $blue-800,
        "900": $blue-900
    )
);

$variant-colors-rgb-map: (
    "primary": (
        "100": $blue-100-rgb,
        "200": $blue-200-rgb,
        "300": $blue-300-rgb,
        "400": $blue-400-rgb,
        "500": $blue-500-rgb,
        "600": $blue-600-rgb,
        "700": $blue-700-rgb,
        "800": $blue-800-rgb,
        "900": $blue-900-rgb
    ),
    "secondary": (
        "100": $gray-100-rgb,
        "200": $gray-200-rgb,
        "300": $gray-300-rgb,
        "400": $gray-400-rgb,
        "500": $gray-500-rgb,
        "600": $gray-600-rgb,
        "700": $gray-700-rgb,
        "800": $gray-800-rgb,
        "900": $gray-900-rgb
    ),
    "success": (
        "100": $green-100-rgb,
        "200": $green-200-rgb,
        "300": $green-300-rgb,
        "400": $green-400-rgb,
        "500": $green-500-rgb,
        "600": $green-600-rgb,
        "700": $green-700-rgb,
        "800": $green-800-rgb,
        "900": $green-900-rgb
    ),
    "warning": (
        "100": $orange-100-rgb,
        "200": $orange-200-rgb,
        "300": $orange-300-rgb,
        "400": $orange-400-rgb,
        "500": $orange-500-rgb,
        "600": $orange-600-rgb,
        "700": $orange-700-rgb,
        "800": $orange-800-rgb,
        "900": $orange-900-rgb
    ),
    "danger": (
        "100": $red-100-rgb,
        "200": $red-200-rgb,
        "300": $red-300-rgb,
        "400": $red-400-rgb,
        "500": $red-500-rgb,
        "600": $red-600-rgb,
        "700": $red-700-rgb,
        "800": $red-800-rgb,
        "900": $red-900-rgb
    ),
    "highlight": (
        "100": $blue-100-rgb,
        "200": $blue-200-rgb,
        "300": $blue-300-rgb,
        "400": $blue-400-rgb,
        "500": $blue-500-rgb,
        "600": $blue-600-rgb,
        "700": $blue-700-rgb,
        "800": $blue-800-rgb,
        "900": $blue-900-rgb
    )
);
