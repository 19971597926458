@use "../variables/colors";
@use "../mixins/dropzone-mixin";

.atlas-dropzone-expanded-drag.dz-drag-hover {
    position: relative;

    &::after {
        width: calc(100% - 3rem);
        height: calc(100% - 3rem);
        top: 1.5rem;
        left: 1.5rem;

        @include dropzone-mixin.drop-files-background(2.5rem, colors.$atlas-primary-rgb, "Solte os arquivos aqui");
    }

    &.atlas-dropzone-max-files-reached {
        &::after {
            width: calc(100% - 3rem);
            height: calc(100% - 3rem);
            top: 1.5rem;
            left: 1.5rem;

            @include dropzone-mixin.drop-files-background(
                2.5rem,
                colors.$danger,
                attr(data-message-drag-max-files-exceeded)
            );
        }
    }
}
