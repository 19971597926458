/* Font family */
$font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$font-size-root: 16px;

/* Display */
$display1-font-size: 6rem;
$display2-font-size: 5.5rem;
$display3-font-size: 4.5rem;
$display4-font-size: 3.5rem;
$display-line-height: 1.2;
$display-font-weight: 300;

$display-sizes: (
    "display1": $display1-font-size,
    "display2": $display2-font-size,
    "display3": $display3-font-size,
    "display4": $display4-font-size
);

/* Heading */
$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;
$heading-line-height: 1.2;
$heading-font-weight: 600;

$heading-sizes: (
    "h1": $h1-font-size,
    "h2": $h2-font-size,
    "h3": $h3-font-size,
    "h4": $h4-font-size,
    "h5": $h5-font-size,
    "h6": $h6-font-size
);

/* Body */
$body-lg-font-size: 1.25rem;
$body-md-font-size: 1rem;
$body-sm-font-size: 0.875rem;
$body-xsm-font-size: 0.8rem;
$body-line-height: 1.5;
$body-font-weight: 400;
$body-font-weight-bold: 700;

$body-sizes: (
    "lg": $body-lg-font-size,
    "md": $body-md-font-size,
    "sm": $body-sm-font-size,
    "xsm": $body-xsm-font-size
);

/* Caption */
$caption-font-size: 0.75rem;
$caption-small-font-size: 0.5rem;
$caption-line-height: 1;
$caption-font-weight: 600;

$atlas-font-mapping: (
    "display": (
        "sizes": $display-sizes,
        "line-height": $display-line-height,
        "font-weight": $display-font-weight
    ),
    "heading": (
        "sizes": $heading-sizes,
        "line-height": $heading-line-height,
        "font-weight": $heading-font-weight
    ),
    "body": (
        "sizes": $body-sizes,
        "line-height": $body-line-height,
        "font-weight": $body-font-weight,
        "font-weight-bold": $body-font-weight-bold
    ),
    "caption": (
        "sizes": (
            "default": $caption-font-size,
            "small": $caption-small-font-size
        ),
        "line-height": $caption-line-height,
        "font-weight": $caption-font-weight
    )
);

/* BOOTSTRAP VARIABLES */
$headings-font-weight: 600;
$font-family-sans-serif: $font-family;
$input-btn-font-family: $font-family;
