@use "../variables/colors";
@use "../variables/z-index";
@use "../mixins/breakpoints";

.mobile-backdrop {
    width: 105vw;
    height: 105vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(colors.$atlas-secondary-700, 40%);
    z-index: z-index.$zindex-mobile-backdrop;

    @include breakpoints.breakpoint-up-lg {
        display: none;
        visibility: hidden;
    }
}

.desktop-backdrop {
    width: 105vw;
    height: 105vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(colors.$atlas-secondary, 40%);
    z-index: z-index.$zindex-desktop-backdrop;
}
