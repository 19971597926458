@use "variables/typography";
@use "variables/colors";

*[hidden] {
    display: none !important;
}

.hide {
    display: none;
}

html {
    margin: 0;
}

body {
    margin: 0;
    visibility: hidden;

    &.has-atlas {
        visibility: visible;
    }
}

code {
    font-size: typography.$body-xsm-font-size;
    color: colors.$pink;
    word-wrap: break-word;

    a > & {
        color: inherit;
    }
}

.disable-scroll {
    overflow: hidden;
}

ul.atlas-simple-list {
    color: colors.$text-muted;

    &.small {
        margin: 0;
        padding-left: 1rem;

        li {
            font-size: typography.$body-xsm-font-size;
            line-height: typography.$body-line-height;
            color: colors.$text-muted;
        }
    }
}
