@use "colors";

/* Border radius */
$border-radius: 0.25rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: 0.25rem;
$border-radius-xl: 0.5rem;
$border-radius-circle: 50%;
$border-radius-pill: 9999px;

/* Base borders */
$border-thin-default: 1px solid colors.$gray-border;
