@use "sass:map";
@use "../variables/colors";
@use "../variables/typography";

:root {
    font-size: typography.$font-size-root;

    /* Colors */
    @each $theme, $color in colors.$theme-colors {
        --atlas-#{$theme}: #{$color};
    }

    --atlas-light: #{colors.$light};
    --atlas-light-rgb: #{colors.$light-rgb};
    --atlas-dark: #{colors.$dark};
    --atlas-dark-rgb: #{colors.$dark-rgb};

    @each $theme, $variations in colors.$variant-colors-map {
        @each $variation, $color in $variations {
            --atlas-#{$theme}-#{$variation}: #{$color};
        }
    }

    @each $theme, $color in colors.$contrast-theme-colors {
        --atlas-contrast-#{$theme}: #{$color};
    }

    @each $theme, $color in colors.$rgb-colors-map {
        --atlas-#{$theme}-rgb: #{$color};
    }

    @each $theme, $variations in colors.$variant-colors-rgb-map {
        @each $variation, $color in $variations {
            --atlas-#{$theme}-#{$variation}-rgb: #{$color};
        }
    }

    /* Typography */
    --atlas-font-family: #{typography.$font-family};

    @each $font, $config in typography.$atlas-font-mapping {
        @each $size, $font-size in map.get($config, "sizes") {
            @if $font == "body" or $font == "caption" {
                --atlas-#{$font}-#{$size}-font-size: #{$font-size};
            } @else {
                --atlas-#{$size}-font-size: #{$font-size};
            }
        }

        --atlas-#{$font}-line-height: #{map.get($config, "line-height")};
        --atlas-#{$font}-font-weight: #{map.get($config, "font-weight")};

        @if $font == "body" {
            --atlas-#{$font}-font-weight-bold: #{map.get($config, "font-weight-bold")};
        }
    }
}
