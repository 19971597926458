/* Import das fontes */
@import "../assets/fonts/atlas-icons/atlas-icons";
@import "../assets/fonts/open-sans/open-sans";

/* Definições globais do bootstrap */
@import "bootstrap-config";
@import "bootstrap/scss/root";

/* Componentes que necessitam de estilos globais */
@import "reboot";
@import "globals/utils";
@import "globals/root";
@import "components/alert";
@import "components/backdrop";
@import "components/dropzone";
@import "components/loading";
