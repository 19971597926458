@font-face {
    font-family: "Open Sans";
    src: url("fonts/OpenSans-400-webfont.woff2") format("woff2"), url("fonts/OpenSans-400-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("fonts/OpenSans-400-italic-webfont.woff2") format("woff2"),
        url("fonts/OpenSans-400-italic-webfont.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("fonts/OpenSans-600-webfont.woff2") format("woff2"), url("fonts/OpenSans-600-webfont.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("fonts/OpenSans-600-italic-webfont.woff2") format("woff2"),
        url("fonts/OpenSans-600-italic-webfont.woff") format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("fonts/OpenSans-700-webfont.woff2") format("woff2"), url("fonts/OpenSans-700-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("fonts/OpenSans-700-italic-webfont.woff2") format("woff2"),
        url("fonts/OpenSans-700-italic-webfont.woff") format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
