@use "../variables/borders";
@use "../variables/colors";
@use "../variables/typography";
@use "../variables/z-index";
@use "../functions/encode";

@mixin dropzone-border-image($color) {
    background-image: encode.escape-svg(
        url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='4' ry='4' stroke='#{$color}' stroke-width='1' stroke-dasharray='7 7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")
    );
}

@mixin drop-files-background($font-size, $color, $content) {
    z-index: z-index.$zindex-dropzone;
    content: $content;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: $font-size;
    font-family: typography.$font-family;
    font-weight: typography.$body-font-weight-bold;
    color: rgba($color, 0.2);
    border-radius: borders.$border-radius;
    padding: 4rem;
    box-sizing: border-box;
    border: 1px dashed rgba($color, 1);
    background-color: rgba(colors.$white, 90%);
    background-image: none;
}
